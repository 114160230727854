import React from 'react';
import { Button } from '@material-ui/core';
import { IParcelState } from '../../../states/app-state';
import styles from './ParcelCompact.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const ParcelCompact: React.FC<{ parcel: IParcelState }> = ({ parcel }) => {

    const isDisabled = parcel.isDisabled;
    const handleEdit = () => parcel.setEditing(true);
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div>
                    <h2>Parcel Type</h2>
                </div>
                {!isDisabled && <div>
                    <Button variant="text" className={styles.edit} onClick={handleEdit} >Edit</Button>
                </div>}

            </div>
            {
                parcel.type?.parcel_type &&
                <div className={styles.type}>
                    <div className={styles.image}>
                        <img src={parcel.type?.parcel_img_url} alt="" className={''} />
                    </div>
                    <div>{parcel.type?.parcel_type}</div>
                </div>
            }
        </div>
    );
    // modified by Bearer Web Developer end
}
export default ParcelCompact;
