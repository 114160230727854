// this file added by Bearer Web Developer
import { Button } from "@material-ui/core";
import styles from './defaultOriginLocation.module.scss';
import React from "react";
import { useAppState } from "../../../states/app-state";
import { TFavouritePlacesInfo } from "../../../api/bearer-api";
import RemoveFavouritePlacesConfirm from "../removeFavouritePlacesConfirm";
import EditOriginLocation from "./editOriginLocation";

interface Pros {
    defaultOrigin: TFavouritePlacesInfo | null,
    onSelect: (selected: TFavouritePlacesInfo) => void,
    setDefaultOriginFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DefaultOriginLocation: React.FC<Pros> = ({ defaultOrigin, onSelect, setDefaultOriginFormOpen }) => {
    const appState = useAppState();
    const [removeConfirmOpen, setRemoveConfirmOpen] = React.useState(false); // added by Bearer Web Developer
    const handleRemoveConfirmClose = () => {
        setRemoveConfirmOpen(false);
    }
    const [loading, setLoading] = React.useState(false);
    const handleRemoveFavouritePlace = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (defaultOrigin) {
            setLoading(true);
            await appState.favouritePlaces.removeFavouritePlaces(defaultOrigin?.id);
            setLoading(false);
        }

    }
    const handleSelect = () => {
        if (defaultOrigin) onSelect(defaultOrigin)
    };
    const handleDeleting = (e: React.MouseEvent) => {
        e.stopPropagation();
        setRemoveConfirmOpen(true);
    }

    // modified by Bearer Web Developer start
    return (
        <>
            {
                defaultOrigin ?
                    <div className={styles.favourite_place} onClick={handleSelect}>
                        <RemoveFavouritePlacesConfirm handleRemoveFavouritePlace={handleRemoveFavouritePlace} loading={loading}
                            open={removeConfirmOpen} handleClose={handleRemoveConfirmClose} favouritePlaceId={defaultOrigin?.id} />
                        <div className={styles.favourite_place_info}>
                            <div className={styles.location_favourite_place}>
                                <img src="/edit_location_white.svg" alt="edit" />
                            </div>
                            <div className={styles.list_item_text}>
                                <h6>{defaultOrigin?.place_name} <span>{"("}Your Default Origin Location{")"}</span></h6>
                                <p>{defaultOrigin?.address}</p>
                            </div>
                        </div>
                        <Button onClick={handleDeleting} >
                            <img src="/delete_white.png" alt="delete" />
                        </Button>
                    </div>
                    :
                    <EditOriginLocation setDefaultOriginFormOpen={setDefaultOriginFormOpen} />

            }
        </>
    )
    // modified by Bearer Web Developer end
}
export default DefaultOriginLocation;