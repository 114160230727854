import React from 'react';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import TransportTypeImage from './TransportTypeImage';
import { IOrderState, ITransportState, useAppState } from '../../states/app-state';
import styles from './TransportsCompact.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const TransportsCompact: React.FC<{ transport: ITransportState, selectedOrder: IOrderState }> = observer(({ transport, selectedOrder }) => {

    const isDisabled = transport.isDisabled;
    // added by Bearer Web Developer start
    const appState = useAppState();
    const { order } = appState.selectedOrder!.payment;
    const realPrice = order?.selectedTransportOption?.price;
    const madeOfTransport = selectedOrder.transportOptions.selectedOption!;
    const capitalizeFirstLetterMadeOfTransport = madeOfTransport?.slice(0,1).toLocaleUpperCase() + madeOfTransport?.slice(1).toLocaleLowerCase();
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.transport}>Mode of Transport</div>
                {!isDisabled &&
                    <div>
                        <Button variant="text" className={styles.edit} onClick={() => transport.setEditing(true)}>Edit</Button>
                    </div>}
            </div>
            {
                selectedOrder.transportOptions.selectedOption &&
                <div className={styles.transport_description}>
                    <div>
                        <TransportTypeImage type={selectedOrder.transportOptions.selectedOption} />
                        <h3 className={''}>{capitalizeFirstLetterMadeOfTransport}</h3>
                    </div>
                    {/* added by Bearer Web Developer start */}
                    <div>
                        <h4 className={''}>{order?.selectedTransportOption?.time}</h4>
                        <h5 className={''}>${realPrice}</h5>
                    </div>
                    {/* added by Bearer Web Developer end */}
                </div>
            }
        </div>
    );
    // modified by Bearer Web Developer end
})
export default TransportsCompact;


