import React from "react";
import { Button, CircularProgress, Dialog, DialogContentText } from "@material-ui/core";
import { useAppState } from "../../states/app-state";
import { TFavouritePlacesInfo } from "../../api/bearer-api";
import { observer } from "mobx-react-lite";
import FavouritePlacesForm from "./FavouritePlacesForm";
import styles from './FavouritePlacesList.module.scss'; // added by Bearer Web Developer
import DefaultOriginLocation from "./defaultOriginLocation"; // added by Bearer Web Developer
import RemoveFavouritePlacesConfirm from "./removeFavouritePlacesConfirm"; // added by Bearer Web Developer
import DefaultOriginForm from "./defaultOriginForm"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


interface IFavouritePlacesListProps { open: boolean, handleClose: () => void, onSelect: (selected: TFavouritePlacesInfo) => void }
const FavouritePlacesList: React.FC<IFavouritePlacesListProps> = ({ open, handleClose, onSelect }) => {
    const appState = useAppState();
    const { favouritePlacesOptions } = appState.favouritePlaces;
    const favouritePlaces = favouritePlacesOptions?.filter(((value) => (value?.is_set_as_default_origin !== true))); // added by Bearer Web Developer
    const [favouritePlacesFormOpen, setFavouritePlacesFormOpen] = React.useState(false);
    const handleFavouritePlacesForm = () => {
        setFavouritePlacesFormOpen(true);
    }
    const handleFavouritePlacesFormClose = () => {
        setFavouritePlacesFormOpen(false);
    }

    React.useEffect(() => {
        if (appState.login.loggedin) {
            appState.favouritePlaces.prepareFavouritePlacesOptions(false);
        }
    }, [appState.login.loggedin]); // remove appState [appState, appState.login.loggedin] by Bearer Web Developer
    // added by Bearer Web Developer start
    const defaultOrigin: TFavouritePlacesInfo | null = favouritePlacesOptions?.filter(((value) => (value?.is_set_as_default_origin === true)))?.[0] || null;
    const [defaultOriginFormOpen, setDefaultOriginFormOpen] = React.useState(false);
    const handleDefaultOriginFormClose = () => {
        // open defaultOriginForm
        setDefaultOriginFormOpen(false)
    };
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            <FavouritePlacesForm open={favouritePlacesFormOpen} handleClose={handleFavouritePlacesFormClose} />
            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: "10px" } }} classes={{
                paperWidthSm: styles.paper_width_sm
            }}>
                <div className={styles.favourite_places}>
                    <div className={styles.header}>
                        <h3>Saved Addresses: </h3>
                        <div className={styles.add_and_cancel}>
                            <Button
                                className={styles.add}
                                onClick={handleFavouritePlacesForm}
                            >
                                + Add
                            </Button>
                            <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                                </svg>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <DialogContentText>
                            <DefaultOriginForm open={!defaultOrigin ? defaultOriginFormOpen : false} handleClose={handleDefaultOriginFormClose} /> {/* added by Bearer Web Developer */}
                            <DefaultOriginLocation setDefaultOriginFormOpen={setDefaultOriginFormOpen} defaultOrigin={defaultOrigin} onSelect={onSelect} /> {/* added by Bearer Web Developer */}
                            {favouritePlaces?.map((favouritePlaceOption, i) => <FavouritePlace key={favouritePlaceOption.id} favouritePlacesOptions={favouritePlaceOption} withDivider={i !== 0} onSelect={onSelect} />)}  {/* favouritePlacesOptions filtered as favouritePlaces for not showing default origin by Bearer Web Developer */}
                            {!favouritePlacesOptions && <CircularProgress />}
                        </DialogContentText>
                    </div>
                </div>
            </Dialog>
        </>
    )
    // modified by Bearer Web Developer end
}
export default observer(FavouritePlacesList);

const FavouritePlace: React.FC<{ favouritePlacesOptions: TFavouritePlacesInfo, withDivider: boolean, onSelect: (selected: TFavouritePlacesInfo) => void }> = observer(({ favouritePlacesOptions: favouritePlacesOption, onSelect }) => {
    const appState = useAppState();
    // added by Bearer Web Developer start
    const [removeConfirmOpen, setRemoveConfirmOpen] = React.useState(false); // added by Bearer Web Developer
    const handleRemoveConfirmClose = () => {
        setRemoveConfirmOpen(false);
    }
    // added by Bearer Web Developer end
    const [loading, setLoading] = React.useState(false);
    // const [deleting, setDeleting] = React.useState(false); // removed by Bearer Web Developer
    const handleRemoveFavouritePlace = async (e: React.MouseEvent) => {
        e.stopPropagation();
        setLoading(true);
        await appState.favouritePlaces.removeFavouritePlaces(favouritePlacesOption.id);
        setLoading(false);
    }
    // modified by Bearer Web Developer start
    const handleSelect = () => {
        onSelect(favouritePlacesOption)
        // handlePlaceSelected
    };
    // modified by Bearer Web Developer end
    // modified by Bearer Web Developer start
    const handleDeleting = (e: React.MouseEvent) => {
        e.stopPropagation();
        setRemoveConfirmOpen(true); // modified by Bearer Web Developer
    }
    // modified by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <div className={styles.favourite_place} onClick={handleSelect}>
            {/* The RemoveFavouritePlacesConfirm component added by Bearer Web Developer */}
            <RemoveFavouritePlacesConfirm handleRemoveFavouritePlace={handleRemoveFavouritePlace} loading={loading}
                open={removeConfirmOpen} handleClose={handleRemoveConfirmClose} favouritePlaceId={favouritePlacesOption.id} />
            <div className={styles.favourite_place_info}>
                <div className={styles.location_favourite_place}>
                    <img src="/location_favorite_place.png" alt="" />
                </div>
                <div className={styles.list_item_text}>
                    <h6>{favouritePlacesOption.place_name}</h6>
                    <p className={''}>{favouritePlacesOption.address}</p>
                </div>
            </div>
            <Button onClick={handleDeleting} >
                <img src="/delete.png" alt="delete" />
            </Button>
        </div>
    )
    // modified by Bearer Web Developer end
})