import React from "react";
import { Button, Dialog } from "@material-ui/core";
import BearerTextField from "../../components/fields/BearerTextField";
import { useAppState } from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './DiscountCodePopup.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const DiscountCodePopup: React.FC = observer(() => {

    const appState = useAppState();
    const discountcode = appState.selectedOrder?.discount;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        const r = appState.login.loggedin;
        const f = appState.selectedOrder?.transport.isComplete;
        if (r && f) {
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        const valid = !!discountcode?.discountCode && discountcode.discountCode.trim();
        if (valid) {
            try {
                setLoading(true);
                setError('');
                const r = await appState.selectedOrder?.fetchDiscount();
                // console.log({r})
                r && setOpen(false);
            } catch (err: any) {
                setLoading(false);
                setError(err)
            }
        }
    }
    // modified by Bearer Web Developer start
    return (
        <>
            <div className={styles.discount_code}>
                <Button classes={{ root: styles.discount_code_button }} disableRipple={true} onClick={handleClickOpen}>
                    <span className={styles.text}>Apply a Promo Code</span>
                </Button>
            </div>
            <Dialog open={open} PaperProps={{ style: { borderRadius: "10px" } }}>
                <div className={styles.discount_code_dialog}>
                    <div className={styles.header}>
                        <h3>Promo Code</h3>
                        <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                            </svg>
                        </Button>
                    </div>
                    <div className={styles.text}>
                        <h6>Please enter your code</h6>
                    </div>
                    <Observer>{() => (
                        <div className={''}>
                            <BearerTextField
                                colorStyle='white'
                                label="Promo Code"
                                placeholder="Enter Your Code"
                                value={discountcode?.discountCode}
                                onChange={(e) => discountcode?.setDiscountCode(e.target.value)}
                                clear={()=>{ discountcode?.setDiscountCode('') }} // added by Bearer Web Developer
                            />
                            {error &&
                                <div className={styles.error}>
                                    <img src="/error-red.png" alt="" />
                                    <div className={''}>{error}</div>
                                </div>
                            }
                        </div>
                    )}</Observer>
                    <>
                        <Observer>{() => (
                            <>
                                <div>
                                    {/* <Button
                                    
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button> */}
                                </div>
                                <>
                                    <Observer>{() => {
                                        const valid = !!discountcode?.discountCode && discountcode.discountCode.trim();
                                        return (
                                            <>
                                                <Button
                                                    className={styles.apply}
                                                    onClick={handleConfirm}
                                                    disabled={!valid}
                                                >
                                                    Apply
                                                </Button>
                                                {loading && <DisabledClickLoading />}
                                            </>
                                        );
                                    }}</Observer>
                                </>
                            </>
                        )}</Observer>
                    </>
                </div>
            </Dialog>
        </>
    );
    // modified by Bearer Web Developer end
});
export default DiscountCodePopup;
