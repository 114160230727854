// this file added by Bearer Web Developer
import React from "react";
import { Button, Dialog, Typography } from "@material-ui/core";
import { observer, Observer, useLocalObservable } from "mobx-react-lite";
import AddressField from "../../../components/fields/AddressField";
import BearerTextField from "../../../components/fields/BearerTextField";
import { FavouritePlacesFormState, useAppState } from "../../../states/app-state";
import FavouritePlaceMap from "../FavouritePlaceMap";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { error } from "../../../app/log"; // added by Bearer Web Developer
import styles from './defaultOriginForm.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

interface IFavouritePlacesFormProps { open: boolean, handleClose: () => void }
const DefaultOriginForm: React.FC<IFavouritePlacesFormProps> = observer(({ open, handleClose }) => {

    const appState = useAppState();
    const state = useLocalObservable(() => FavouritePlacesFormState.create())
    const [loading, setLoading] = React.useState(false);
    const handleAddFavouritePlace = async () => {
        try {
            setLoading(true);
            await appState.favouritePlaces.addFavouritePlaces(state, true);
            state.clearAddressAndPlaceName(); // added by Bearer Web Developer
            setLoading(false);
            handleClose();

        } catch (err) {
            error(err); // modify by Bearer Web Developer
            setLoading(false);
        }
    }
    // modified by Bearer Web Developer start
    return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: "10px" } }} classes={{
            paperWidthSm: styles.paper_width_sm
        }}>
            <div className={styles.favourite_places_form}>
                <div className={styles.header}>
                    <div>
                        <h5>Default Origin Location Details</h5>
                    </div>
                    <div>
                        <Button onClick={handleClose}>
                            <img src="/back.png" alt="back" />
                        </Button>
                    </div>
                </div>
                <div className={styles.map}>
                    <FavouritePlaceMap location={state.address?.location} />
                </div>
                <div>
                    <Observer>{() => (
                        <AddressField
                            colorStyle="white"
                            value={state.address || null}
                            onChange={(value) => state.setAddress(value)}
                            name={"address"}
                        />
                    )}</Observer>
                </div>
                <div className={styles.place}>
                    <div>
                        <Observer>{() => (
                            <BearerTextField
                                colorStyle="white"
                                placeholder="e.g., Head Office"
                                label="Place Name"
                                value={state.placeName}
                                onChange={(e) => state.setPlaceName(e.target.value)}
                                clear={()=>{ state.setPlaceName('') }} // added by Bearer Web Developer
                            />
                        )}</Observer>
                    </div>
                    <div>
                        <Observer>{() => (
                            <>
                                <BearerTextField
                                    colorStyle="white"
                                    placeholder="e.g., Level 5"
                                    label="Place Details"
                                    value={state.placeDetails}
                                    onChange={(e) => state.setPlaceDetails(e.target.value)}
                                    clear={()=>{ state.setPlaceDetails('') }} // added by Bearer Web Developer
                                />
                                <Typography variant="caption">Hint: Floor, Flat, Unit, Door, etc.</Typography>
                            </>
                        )}</Observer>
                    </div>
                </div>
                <div>
                    <Observer>{() => {
                        const valid = !!state.address && !!state.placeName.trim();
                        return (
                            <>
                                <Button
                                    className={styles.add}
                                    onClick={handleAddFavouritePlace}
                                    disabled={!valid}
                                >
                                    Add to Favourites
                                </Button>
                                {loading && <DisabledClickLoading />}
                            </>
                        )
                    }}</Observer>
                </div>
            </div>
        </Dialog>
    )
    // modified by Bearer Web Developer end
})
export default DefaultOriginForm;