import React from 'react';
import BearerTextField from '../../../components/fields/BearerTextField';
import { Button } from '@material-ui/core';
import RightRadioButton from '../../../components/rightradiobutton/RightRadioButton';
import { ApprovalMethodEnum, IDestinationState, IParcelState, useAppState } from '../../../states/app-state';
import { Observer } from 'mobx-react-lite';
import AddressField from '../../../components/fields/AddressField';
import FavouritePlacesList from '../../favouritePlaces/FavouritePlacesList';
import LoginPopup from '../../login/LoginPopup';
import { analytics, TFavouritePlacesInfo } from '../../../api/bearer-api';
import styles from './DestinationEditor.module.scss'; // added by Bearer Web Developer
import { logEvent } from 'firebase/analytics'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


const DestinationEditor: React.FC<{ destination: IDestinationState, parcel: IParcelState }> = ({ destination, parcel }) => {

  const appState = useAppState();
  const handleConfirm = () => {
    const valid = !!destination.address && !!(destination.phone.length === 10) && !!(destination.name.length >= 2) && !!destination.approvalMethods;
    if (valid) {
      // added by Bearer Web Developer start
      // Firebase Analytics Event Name Destination Add. Confirmed
      // App Event Destination Address Conformed by User
      logEvent(analytics,"destination_address_confirmed");
      // added by Bearer Web Developer end
      // added by Bearer Web Developer start
      // Firebase Analytics Event Name Parcel Security (Final)
      // App Event Destination Details + Destination Verification Method Chosen
      logEvent(analytics,"selected_drop_off_verification_method",{
        dropoff_verification_method: appState?.selectedOrder?.destination?.approvalMethods
      });
      // added by Bearer Web Developer end
      destination.setEditing(false);
      parcel.setEditing(true);
    }
  }




  const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);
  // const handleLoginPopup = () => {
  //     setLogInPopupOpen(true);
  // }
  const handleLoginInPopupClose = () => {
    setLogInPopupOpen(false);
  }
  const [favouritePlacesListOpen, setFavouritePlacesListOpen] = React.useState(false);
  const handleFavouritePlacesList = () => {
    if (!appState.login.loggedin) {
      setLogInPopupOpen(true)
    } else {
      setFavouritePlacesListOpen(true);
    }
  }
  const handleFavouritePlacesListClose = () => {
    setFavouritePlacesListOpen(false);
  }
  const handleFavouriteSelected = (selected: TFavouritePlacesInfo) => {
    destination.setAddress({
      description: selected.address,
      location: {
        lat: selected.location_lat, lng: selected.location_lng
      }
    });
    destination.setMoreDetails(selected.details);
    // added by Bearer Web Developer start
    // Firebase Analytics Event Name Favourite Place Used
    // App Event Origin || Destination Address chosen from Favourite Places
    logEvent(analytics,"favourite_place_used");
    // added by Bearer Web Developer end
    setFavouritePlacesListOpen(false);
  }

  // modified by Bearer Web Developer start
  return (
    <>
      <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
      <FavouritePlacesList onSelect={handleFavouriteSelected} open={favouritePlacesListOpen} handleClose={handleFavouritePlacesListClose} />
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.destination}>
            <img src="/destination.png" alt="destination" />
            <h2>Destination</h2>
          </div>
          <Button variant="text" disableRipple={true} className={styles.clear} onClick={() => destination.clear()}>Clear Fields</Button>
        </div>
        <div>
          <Observer>{() => (
            <AddressField
              value={destination.address || null}
              onChange={(value) => destination.setAddress(value)}
              name={"destination"}
            />
          )}</Observer>
        </div>
        <div>
          <Observer>{() => (
            <BearerTextField
              name='More Details'
              placeholder={"e.g., Level 5 | Shop # G8 | The Glass Door"}
              label="More Details/ Message for the Rider"
              value={destination.moreDetails}
              onChange={(e) => destination.setMoreDetails(e.target.value)}
              clear={()=>{ destination.setMoreDetails('') }} // added by Bearer Web Developer
            />
          )}</Observer>
        </div>
        <div className={styles.extra_inputs}>
          <div className={styles.phone_number}>
            <Observer>{() => (
              <BearerTextField
                label="Phone Number"
                placeholder={"e.g., 0300 000 000"}
                value={destination.phone}
                inputProps={{ maxLength: "10" }} // add maxlength of Phone Number of destination by Bearer Web Developer
                onChange={(e) => destination.setPhone(e.target.value)}
                clear={()=>{ destination.setPhone('') }} // added by Bearer Web Developer
                validator={validatePhone}
              />
            )}</Observer>
          </div>
          <div className={styles.recipient_name}>
            <Observer>{() => (
              <BearerTextField
                label="Recipient Name"
                placeholder={"e.g., John Citizen"}
                value={destination.name}
                onChange={(e) => destination.setName(e.target.value)}
                clear={()=>{ destination.setName('') }} // added by Bearer Web Developer
                validator={validateName}
              />
            )}</Observer>
          </div>
        </div>
        <div className={styles.delivery}>
          <p>Need to verify the recipient at the destination with a security code?</p>
        </div>
        <div>
          <Observer>{() => (
            <RightRadioButton
              label="Yes, secure with a code"
              value={ApprovalMethodEnum.Code}
              checked={destination.approvalMethods === ApprovalMethodEnum.Code}
              onClick={() => (destination.setApprovalMethods(ApprovalMethodEnum.Code))}
            />
          )}</Observer>
        </div>
        <div>
          <Observer>{() => (
            <RightRadioButton
              label="No, verification not required"
              value={ApprovalMethodEnum.None}
              checked={destination.approvalMethods === ApprovalMethodEnum.None}
              onClick={() => (destination.setApprovalMethods(ApprovalMethodEnum.None))}
            />
          )}</Observer>
        </div>
        <div className={styles.buttons}>
          <Button className={styles.favourite_places} onClick={handleFavouritePlacesList}>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
              <rect width="50" height="50" rx="10" fill="#EDEDED" />
              <rect x="6" y="6" width="38" height="38" rx="8" fill="#EDEDED" />
              <path d="M17 36V20C17 19.45 17.1741 18.9792 17.5222 18.5875C17.8704 18.1958 18.2889 18 18.7778 18H27.6667C28.1556 18 28.5741 18.1958 28.9222 18.5875C29.2704 18.9792 29.4444 19.45 29.4444 20V36L23.2222 33L17 36ZM18.7778 32.95L23.2222 30.8L27.6667 32.95V20H18.7778V32.95ZM31.2222 33V16H19.6667V14H31.2222C31.7111 14 32.1296 14.1958 32.4778 14.5875C32.8259 14.9792 33 15.45 33 16V33H31.2222Z" fill="#14438E" />
            </svg>
          </Button>
          <Observer>{() => {
            const valid = destination.address && !!(destination.phone.length === 10) && !!(destination.name.length >= 2) && !!destination.approvalMethods;
            return (
              <Button disabled={!valid} className={styles.confirm_destination} onClick={handleConfirm}>Confirm Destination</Button>
            );
          }}</Observer>
        </div>
      </div>
    </>
  );
  // modified by Bearer Web Developer end
}
export default DestinationEditor;

function validatePhone(value: string) {
  if (!(value.length === 10)) return "10 Digits (Beginning with a 0)";
  if (!(value.slice(0, 1) === "0")) return "10 Digits (Beginning with a 0)";
  if (!value.match(/^[0-9]*$/)) return "10 Digits (Beginning with a 0)";
}


function validateName(value: string) {
  if (!(value.length >= 2)) return "Minimum 2 letters (English Only)";
  if (!(value.match(/^[a-zA-Z\s]*$/))) return "Minimum 2 letters (English Only)";
}