import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import { PlaceType, PlaceTypeWithLocation } from '../../../states/app-state';
import { googleMap } from '../../../api/google-map-api';
import styles from './AddressField.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer
type IColorStyle = "blue" | "gray" | "white";
const AddressField: React.FC<{ onChange: (value: PlaceTypeWithLocation | null) => void, value: PlaceType | null, name?: string, colorStyle?: IColorStyle }> = ({ value, name, onChange, colorStyle }) => {

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const handleStartSession = () => {
        googleMap.startSession();
    }

    const handleFinishSession = () => {
        googleMap.finishSession();
    }

    const handleChange = async (value: PlaceType | null) => {
        if (value?.place_id) {
            const location = await googleMap.fetchPlaceDetails(value.place_id);
            if (location) {
                // const withLocation = { ...value, location }
                // setOptions((options) => {
                //     return options;
                // });
                // onChange(withLocation);

                value.location = location;
                onChange(value as unknown as PlaceTypeWithLocation);
            }
        }
    }

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (!googleMap.hasSession()) {
            return;
        }

        const { signal, cancel } = createCancellableSignal();

        (async () => {
            const results = await cancellableFetch(inputValue, signal, 500);
            if (!results) return;
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        })();

        return () => {
            active = false;
            cancel();
        };
    }, [value, inputValue]);

    // modified by Bearer Web Developer start
    return (
        <>
            <div className={styles.label}>
                <label className={colorStyle ? styles?.[colorStyle] : styles.blue} htmlFor={name}>Address</label>
            </div>
            <Autocomplete
                id={name}
                getOptionLabel={option => option.description}
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                onFocus={handleStartSession}
                onBlur={handleFinishSession}
                onChange={(event: any, newValue: PlaceType | null) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    handleChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                classes={{
                    listbox: styles.items_list_group,
                    noOptions: styles.items_list_group,
                    endAdornment: styles.end_adornment,
                    popupIndicator: colorStyle === "white" ? styles.popup_indicator_white : styles.popup_indicator_blue
                }}
                noOptionsText={<div className={styles.items_list_group}>Type an Address ...</div>}
                // renderInput modified by Bearer Web Developer start
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}
                        className={`${styles.autocomplete_input} ${colorStyle ? styles?.[colorStyle] : styles.blue}`}>
                        <input name={name || ''} className='active:bg-black w-full'
                            placeholder={"e.g., 447 Collins Street, Melbourne VIC 3000"} {...params.inputProps}
                        />
                        {params.InputProps.endAdornment}
                        {/* <img src='/expand-address.png' onClick={handleStartSession} /> */}
                    </div>
                )}
                // renderInput modified by Bearer Web Developer end
                // renderOption modified by Bearer Web Developer start
                renderOption={(option) => {
                    const matches = option.structured_formatting?.main_text_matched_substrings;
                    const parts = option.structured_formatting && matches && parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <div className={styles.items_list}>
                            <div>
                                <img src="/location-white.png" alt="" />
                            </div>
                            <div>
                                {parts && parts.map((part, index) => (
                                    <span key={index}>{part.text}</span>
                                ))}
                                <p>
                                    {option.structured_formatting && option.structured_formatting.secondary_text}
                                    {!option.structured_formatting && option.description}
                                </p>
                            </div>
                        </div>
                    );
                }}
            // renderOption modified by Bearer Web Developer end
            />
        </>
    );
    // modified by Bearer Web Developer end
}


export default AddressField;
// export default GoogleApiWrapper({
//     apiKey: (process.env.REACT_APP_GOOGLEMAPAPI || "")
// })(AddressField)

function cancellableFetch(input: string, signal: Promise<void>, ms: number) {
    return new Promise<PlaceType[] | undefined>((res, rej) => {
        const timeOut = setTimeout(() => {
            googleMap.fetchAutocomplete(input).then((result) => {
                res(result);
            });
        }, ms);

        signal.catch(() => {
            res(undefined);
            clearTimeout(timeOut);
        });
    });
}

function createCancellableSignal(): { signal: Promise<void>, cancel: () => void } {
    const ret: any = {};
    ret.signal = new Promise((resolve, reject) => {
        ret.cancel = () => {
            reject(new Error("Promise was cancelled"));
        };
    });
    return ret;
}

