// this file added by Bearer Web Developer
import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAppState } from '../../states/app-state';
import AddToHomeScreenPopup from './poup';

const AddToHomeScreen: React.FunctionComponent = observer(() => {

    const deferredprompt = useRef<any>(false);
    const appState = useAppState();
    function handleBeforeInstallPromptEvent(event: any) {
        event.preventDefault();
        if (deferredprompt.current) return;
        deferredprompt.current = event;
        // console.log("beforeinstallprompt event fired and captured");
        appState?.setAddToHomeScreen({
            message: "Add To Home Screen",
            handleClick: () => {
                deferredprompt?.current?.prompt();
            }
        });
    }
    useEffect(function () {
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPromptEvent);
        return function cleanup() {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPromptEvent);
        };
    }, []);
    // added by Bearer Web Developer end
    return (<>{appState?.addToHomeScreen && <AddToHomeScreenPopup/>}</>);
});

export default AddToHomeScreen;
