import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";
import ReceiveReceiptPopup from "./ReceiveReceiptPopup";
import { observer } from "mobx-react-lite";
import { TTransportsHistoryInfo } from "../../api/bearer-api";
import TransportRouteMap from "./TransportRouteMap"; // the name modified by Bearer Web Developer
import ShowImage from "../showImage/ShowImage";
import styles from './TransportHistoryDetail.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

interface ITransportHistoryDetailProps { transportsHistory: TTransportsHistoryInfo }
const TransportHistoryDetail: React.FC<ITransportHistoryDetailProps> = observer(({ transportsHistory }) => {

    const riding = transportsHistory.assigned_bearer_type === "riding";
    // modified by Bearer Web Developer start
    // const [expanded, setExpanded] = React.useState<string | false>(false);
    // const openHandler = (panel: string) => {
    //     setExpanded(panel !== expanded ? panel : false);
    // };
    return (
        <Accordion className={styles.accordion}
            //expanded={expanded === transportsHistory.transport_id}
            elevation={0}
        //onChange={() => openHandler(transportsHistory.transport_id)}

        >
            <AccordionSummary
                classes={{
                    content: styles.MuiAccordionSummary_content,
                    root: styles.MuiAccordionSummary_root,
                    expandIcon: styles.expand_icon
                }}
                expandIcon={
                    <Button className={styles.expand_more}>
                        <img src="/expand_more_black.png" alt="" />
                    </Button>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
            ><div className={styles.more_details}>
                    <div className={styles.details}>
                        <div className={styles.rider}>
                            <div className={styles.image}>
                                <ShowImage fireBaseUrl={transportsHistory.rider_avatar_url} />
                            </div>
                            <p>{transportsHistory.rider_given_name} {transportsHistory.rider_last_name}</p>
                        </div>
                        <div>
                            <div>
                                {riding &&
                                    <div className={styles.riding}>
                                        <div className={styles.motorbike_registration}>
                                            <h6>{transportsHistory.motorbike_registration_number}</h6>
                                            <p>{transportsHistory.motorbike_registration_state}</p>
                                        </div>
                                        <h6 className={styles.motorbike_model}>{transportsHistory.motorbike_make} {transportsHistory.motorbike_model}</h6>
                                    </div>
                                }
                            </div>
                            <div>
                                {/* <Button onClick={() => { openHandler(transportsHistory.transport_id) }} className={
                                    expanded === transportsHistory.transport_id ? styles.expand_more_expanded : styles.expand_more
                                }>
                                    <img src="/expand_more_black.png" alt="" />
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className={styles.map_style}>
                    <TransportRouteMap transportHistoryInfo={transportsHistory} />
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: styles.accordion_details_root }}>
                <ReceiveReceiptPopup transportsHistory={transportsHistory} />
            </AccordionDetails>
        </Accordion>
    )
    // modified by Bearer Web Developer end
})
export default TransportHistoryDetail;
