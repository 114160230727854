import React from "react";
import { Button } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Observer, observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import { TRiderInfo } from "../../api/bearer-api";
import ShowImage from "../showImage/ShowImage";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import styles from './TransportRate.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


const TransportRate: React.FC = observer(() => {

    const appState = useAppState();
    const rating = appState.selectedOrder!.transport.rating;
    const order = appState.selectedOrder!;
    const riderInfo = order!.transport.riderInfo!;
    const riding = riderInfo.bearer_type === "riding"; // added by Bearer Web Developer
    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        setLoading(true);
        await appState.selectedOrder?.transport.rating.customerRateTransport();
        setLoading(false);
    }

    const [loadingSkip, setLoadingSkip] = React.useState(false);
    const handleSkipRatingButton = async () => {
        setLoadingSkip(true);
        await appState.selectedOrder?.transport.rating.customerSkipRating();
        setLoadingSkip(false);
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.transport_rate}>
            <div className={styles.header}>
                <div>
                    <h3>Please rate this transport:</h3>
                </div>
                <div>
                    <Button
                        className={styles.skip}
                        onClick={handleSkipRatingButton}
                        disabled={loadingSkip}
                    >
                        Skip
                    </Button>
                    {loadingSkip && <DisabledClickLoading />}
                </div>
            </div >
            <div className={styles.rider_info}>
                <div className={styles.image}>
                    <div className={styles.image_box}>
                        <ShowImage fireBaseUrl={riderInfo.rider_avatar_url} />
                    </div>
                    <p>{riderInfo.rider_given_name} {riderInfo.rider_last_name}</p>
                </div>
                <div className={styles.type_riding}>
                    <div>
                        {riding &&
                            <>
                                <p>{riderInfo.motorbike_make} {riderInfo.motorbike_model}</p>
                                <div className={styles.motorbike_registration}>
                                    <h5> {riderInfo.motorbike_registration_number}</h5>
                                    <h6>{riderInfo.motorbike_registration_state}</h6>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className={styles.rating}>
                <div className={styles.rate}>
                    <h4>Safe Delivery</h4>
                    <Observer>{() => (
                        <Rating
                            value={rating.safeDelivery}
                            onChange={(_, newValue) => {
                                rating.setSafeDelivery(newValue);
                            }}
                            emptyIcon={<StarBorderIcon className={styles.star} />}
                        />
                    )}</Observer>
                </div>
                <div className={styles.rate}>
                    <h4>Bearer’s Behavior</h4>
                    <Observer>{() => (
                        <Rating
                            value={rating.politeness}
                            onChange={(_, newValue) => {
                                rating.setPoliteness(newValue);
                            }}
                            emptyIcon={<StarBorderIcon color="inherit" className={styles.star} />}
                        />
                    )}</Observer>
                </div>
                <div className={styles.rate}>
                    <h4>Service Speed</h4>
                    <Observer>{() => (
                        <Rating
                            value={rating.speed}
                            onChange={(_, newValue) => {
                                rating.setSpeed(newValue);
                            }}
                            emptyIcon={<StarBorderIcon classes={{root:styles.star}} />}
                        />
                    )}</Observer>
                </div>
            </div>
            <AddressLine riderInfo={riderInfo}/>
            <div>
                <Observer>{() => {
                    const valid = !!rating.politeness && !!rating.safeDelivery && !!rating.speed;
                    return (
                        <>
                            <Button
                                onClick={handleConfirm}
                                disabled={!valid}
                                className={styles.submit}
                            >
                                Submit
                            </Button>
                            {loading && <DisabledClickLoading />}
                        </>
                    );
                }}</Observer>
            </div>
        </div>
    )
    // modified by Bearer Web Developer end
})

export default TransportRate;



const AddressLine: React.FC<{ riderInfo: TRiderInfo }> = observer(({ riderInfo }) => {

        // modified by Bearer Web Developer start
        return (
            <Timeline classes={{ root: styles.time_line }}>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content }}>
                    <TimelineSeparator>
                        <img src="/origin.png" alt="origin" />
                        <TimelineConnector className={''} />
                    </TimelineSeparator>
                    <TimelineContent className={styles.content}>{riderInfo!.origin_address}</TimelineContent>
                </TimelineItem>
                <TimelineItem classes={{ missingOppositeContent: styles.missing_opposite_content,root:styles.timelineItem_root  }}>
                    <TimelineSeparator >
                        <img src="/destination.png" alt="destination" />
                    </TimelineSeparator>
                    <TimelineContent className={styles.content}>{riderInfo!.destination_address}</TimelineContent>
                </TimelineItem>
            </Timeline>
        )
        // modified by Bearer Web Developer end
})




