import React from "react";
import { Button } from "@material-ui/core";
import DiscountCodePopup from "./DiscountCodePopup";
import { PaymentStepEnum, useAppState } from "../../states/app-state";
import { observer } from "mobx-react-lite";
import styles from './PriceEditor.module.scss'; // added by Bearer Web Developer
import { analytics } from "../../api/bearer-api"; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const PriceEditor: React.FC = observer(() => {

    const appState = useAppState();
    const { order, step } = appState.selectedOrder!.payment;
    const realPrice = order?.selectedTransportOption?.price;
    const discountedPrice = order?.discount.value?.transport_receivable_price;
    const riderInfo = order?.transport.riderInfo;
    // const price = order?.discount.value?.transport_receivable_price || order?.selectedTransportOption?.price;
    const handleRemoveDiscoundCode = () => {
        appState.selectedOrder?.payment.removeCouponId();
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Promo Code Removed
        // App Event Remove Dicount Button Clicked
        logEvent(analytics,"promo_code_removed",{
            transport_id: appState.selectedOrder?.transport?.value?.transport_id,
            original_price: appState.selectedOrder?.transport?.value?.transport_original_price,
            receivable_price: discountedPrice?.toString(),
        });
        // added by Bearer Web Developer end
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.price}>
                <h2 className={''}>
                    Price
                </h2>
                {!discountedPrice &&
                    <>
                        {!riderInfo &&
                            <DiscountCodePopup />
                        }
                    </>
                }
            </div>
            {!discountedPrice &&
                <>
                    {step === PaymentStepEnum.FareUpdated && <h4>A$ {appState.selectedOrder?.payment.fareUpdateData?.transport_original_price}</h4>}
                    <h4> {realPrice ? ("$" + realPrice) : '...'}</h4>
                </>
            }
            {discountedPrice &&
                <>
                    {!riderInfo && <div>
                        <Button classes={{ root: styles.remove_button }} disableRipple={true} onClick={handleRemoveDiscoundCode}>
                            <span className={styles.text}>Remove Discount</span>
                        </Button>
                    </div>}
                    {/* added by Bearer Web Developer start */}
                    {
                      riderInfo && <div className={styles.discounted}>
                            <span className={styles.text}>Discounted</span>
                        </div>
                    }
                    {/* added by Bearer Web Developer end */}
                    <div>
                        <h3 className={styles.real_price}>{realPrice ? ("$" + realPrice) : ''}</h3>
                    </div>
                    <div>
                        <h3 className={styles.discounted_price}>{discountedPrice ? ("$" + discountedPrice) : ''}</h3>
                    </div>
                </>
            }
        </div>
    );
    // modified by Bearer Web Developer end
})

export default PriceEditor;