import React, { useRef } from "react";
import { Backdrop, Button, CircularProgress, Dialog } from "@material-ui/core";
import BearerTextField from "../../components/fields/BearerTextField";
import MoreVertical from "../../containers/morevertical/MoreVertical";
import { Observer, observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import AvatarEditor from "./AvatarEditor";
import styles from './ProfileEditorX.module.scss'; // added by Bearer Web Developer
import DeleteIcon from '@material-ui/icons/Delete'; // added by Bearer Web Developer
import LogoutConfirm from "../../components/profile/logoutConfirm"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}
// DialogTitle removed by Bearer Web Developer



interface IProfileEditorProps { open: boolean, handleClose: () => void }
const ProfileEditor: React.FC<IProfileEditorProps> = observer(({ open, handleClose }) => {
    const appState = useAppState();
    // modified by Bearer Web Developer start
    const getInitialData = () => ({
        loaded: appState.profile.loaded,
        touched: {
            firstName: false,
            lastName: false,
            phoneNumber: false,
        },
        firstName: appState.profile.firstName,
        lastName: appState.profile.lastName,
        phoneNumber: appState.profile.phoneNumber
    })
    const handleEditProfileClose = () => {
        handleClose();
        setProfile(getInitialData());
    }
    const [profile, setProfile] = React.useState(getInitialData());
    // modified by Bearer Web Developer end
    // added by Bearer Web Developer start
    function checkvalidation() {
        let validation = true;
        validation = !validateName(profile.firstName) && !validateName(profile.lastName) && !validatePhone(profile.phoneNumber)
        return validation;
    }
    // added by Bearer Web Developer end
    const handleFirstNameChange = (firstName: string) => {
        setProfile({
            ...profile,
            touched: {
                ...profile.touched,
                firstName: true,
            },
            firstName,
        });
    }

    const handleLastNameChange = (lastName: string) => {
        setProfile({
            ...profile,
            touched: {
                ...profile.touched,
                lastName: true,
            },
            lastName,
        });
    }

    const handlePhoneNumberChange = (phoneNumber: string) => {
        setProfile({
            ...profile,
            touched: {
                ...profile.touched,
                phoneNumber: true,
            },
            phoneNumber,
        });
    }
    // handleLogout removed by Bearer Web Developer

    React.useEffect(() => {
        (async () => {
            if (open && !profile.loaded) {
                await appState.profile.fetchProfile();
                const { firstName, lastName, phoneNumber } = appState.profile;
                setProfile(profile => ({
                    ...profile,
                    loaded: true,
                    firstName, lastName, phoneNumber
                }))
            }
        })();
    }, [appState, open, setProfile, profile.loaded]);

    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        try {
            setLoading(true);
            await appState.profile.updateProfile({
                firstName: profile.firstName,
                lastName: profile.lastName,
                phoneNumber: "+61" + profile.phoneNumber.slice(1, 10)
            });
            // added by Bearer Web Developer start
            appState.profile.setFirstName(profile.firstName);
            appState.profile.setLastName(profile.lastName);
            appState.profile.setPhoneNumber(profile.phoneNumber);
            appState.selectedOrder?.origin.setPhone(profile.phoneNumber.slice(0, 10));
            appState.selectedOrder?.origin.setName(`${profile.firstName} ${profile.lastName}`);
            // added by Bearer Web Developer end
            setLoading(false);
            handleEditProfileClose(); // modified by Bearer Web Developer
        } catch (err) {
            setLoading(false);
        }
    }
    const fileInputRef = useRef<HTMLInputElement>(null); // added by Bearer Web Developer
    const [preview, setPreview] = React.useState(""); // added by Bearer Web Developer
    // added by Bearer Web Developer start
    const handleDeleteImage = () => {
        appState.profile.deleteProfileImage();
        setPreview('');
    }
    // added by Bearer Web Developer end
    // added by Bearer Web Developer start
    const [logoutConfirmOpen, setLogoutConfirmOpen] = React.useState(false);
    const handleLogoutConfirmClose = () => {
        setLogoutConfirmOpen(false);
    }
    const handleLogoutConfirm = () => {
        setLogoutConfirmOpen(true);
    }
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <>
            <LogoutConfirm open={logoutConfirmOpen} handleClose={handleLogoutConfirmClose} />
            <Dialog PaperProps={{ style: { borderRadius: "10px" } }}
                className={''}
                onClose={handleEditProfileClose} // modified by Bearer Web Developer
                open={open}
            >
                <div className={styles.root}>
                    <div className={styles.header}>
                        <div>
                            <h2>Profile</h2>
                        </div>
                        <Button className={styles.cancel} disableRipple={true} onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M9.79992 19.833L13.9999 15.633L18.1999 19.833L19.8333 18.1997L15.6333 13.9997L19.8333 9.79967L18.1999 8.16634L13.9999 12.3663L9.79992 8.16634L8.16659 9.79967L12.3666 13.9997L8.16659 18.1997L9.79992 19.833ZM13.9999 25.6663C12.386 25.6663 10.8694 25.3601 9.44992 24.7476C8.03047 24.1351 6.79575 23.3038 5.74575 22.2538C4.69575 21.2038 3.8645 19.9691 3.252 18.5497C2.6395 17.1302 2.33325 15.6136 2.33325 13.9997C2.33325 12.3858 2.6395 10.8691 3.252 9.44967C3.8645 8.03023 4.69575 6.79551 5.74575 5.74551C6.79575 4.69551 8.03047 3.86426 9.44992 3.25176C10.8694 2.63926 12.386 2.33301 13.9999 2.33301C15.6138 2.33301 17.1305 2.63926 18.5499 3.25176C19.9694 3.86426 21.2041 4.69551 22.2541 5.74551C23.3041 6.79551 24.1353 8.03023 24.7478 9.44967C25.3603 10.8691 25.6666 12.3858 25.6666 13.9997C25.6666 15.6136 25.3603 17.1302 24.7478 18.5497C24.1353 19.9691 23.3041 21.2038 22.2541 22.2538C21.2041 23.3038 19.9694 24.1351 18.5499 24.7476C17.1305 25.3601 15.6138 25.6663 13.9999 25.6663ZM13.9999 23.333C16.6055 23.333 18.8124 22.4288 20.6208 20.6205C22.4291 18.8122 23.3333 16.6052 23.3333 13.9997C23.3333 11.3941 22.4291 9.18717 20.6208 7.37884C18.8124 5.57051 16.6055 4.66634 13.9999 4.66634C11.3944 4.66634 9.18742 5.57051 7.37909 7.37884C5.57075 9.18717 4.66659 11.3941 4.66659 13.9997C4.66659 16.6052 5.57075 18.8122 7.37909 20.6205C9.18742 22.4288 11.3944 23.333 13.9999 23.333Z" fill="#858585" />
                            </svg>
                        </Button>
                    </div>
                    {appState.profile.loading && <>
                        {/* the fallowing handleEditProfileClose modified by Bearer Web Developer */}
                        <Backdrop className={''} open={open} onClick={handleEditProfileClose}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>}
                    {appState.profile.loaded && <>
                        <div className={styles.avatar_part}>
                            <div className={styles.avatar_content}>
                                <AvatarEditor fileInputRef={fileInputRef} preview={preview} setPreview={setPreview} />
                            </div>
                            <div className={''}>
                                <h5>{appState.profile.businessName}</h5>
                                <div className={styles.edit}>
                                    <Button style={{ minWidth: "unset", padding: "4px" }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            fileInputRef.current?.click()
                                        }}
                                    >
                                        <img src='/edit.png' />
                                    </Button>
                                    <Button onClick={handleDeleteImage}>
                                        <DeleteIcon color="secondary" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.name}>
                                <div>
                                    <Observer>{() => (
                                        <BearerTextField
                                            label="First Name"
                                            colorStyle="gray"
                                            value={profile.firstName}
                                            onChange={e => handleFirstNameChange(e.target.value)}
                                            clear={()=>{ handleFirstNameChange('') }} // added by Bearer Web Developer
                                            validator={validateName}
                                        />
                                    )}</Observer>
                                </div>
                                <div>
                                    <Observer>{() => (
                                        <BearerTextField
                                            label="Last Name"
                                            colorStyle="gray"
                                            value={profile.lastName}
                                            onChange={e => handleLastNameChange(e.target.value)}
                                            clear={()=>{ handleLastNameChange('') }} // added by Bearer Web Developer
                                            validator={validateName}
                                        />
                                    )}</Observer>
                                </div>
                            </div>
                            <div>
                                <Observer>{() => (
                                    <BearerTextField
                                        label="Phone Number"
                                        colorStyle="gray"
                                        value={profile.phoneNumber}
                                        inputProps={{ maxLength: "10" }} // add maxlength of Phone Number by Bearer Web Developer
                                        onChange={e => handlePhoneNumberChange(e.target.value)}
                                        clear={()=>{ handlePhoneNumberChange('') }} // added by Bearer Web Developer
                                        validator={validatePhone}
                                    />
                                )}</Observer>
                            </div>
                            <div>
                                <Observer>{() => (
                                    <BearerTextField label="Email"
                                        colorStyle="gray"
                                        type="email"
                                        value={appState.profile.email}
                                        disabled
                                    />
                                )}</Observer>
                            </div>
                        </div>
                        <div>
                            <Observer>{() => {
                                const valid = checkvalidation(); // added by Bearer Web Developer
                                return (
                                    <>
                                        <Button
                                            className={styles.save_changes}
                                            onClick={handleConfirm}
                                            disabled={!valid}
                                        >
                                            <span>Save Changes</span>
                                        </Button>
                                        {loading && <DisabledClickLoading />}
                                    </>
                                )
                            }}</Observer>
                        </div>
                        <div className={styles.more}>
                            <Button
                                className={styles.logout}
                                onClick={handleLogoutConfirm}>
                                <span>Log Out</span>
                            </Button>
                            <MoreVertical />
                        </div>
                    </>}
                </div>
            </Dialog>
        </>
    );
    // modified by Bearer Web Developer end
});
export default ProfileEditor;


function validateName(value: string) {
    if (value.length === 0) return "The field must not be empty"; // added by Bearer Web Developer
    if (!(value.length >= 2)) return "Minimum 2 letters (English Only)";
    if (!(value.match(/^[a-zA-Z]*$/))) return "Minimum 2 letters (English Only)";
}

function validatePhone(value: string) {
    if (value.length === 0) return "The field must not be empty"; // added by Bearer Web Developer
    if (!(value.length === 10)) return "10 Digits (Beginning with a 0)";
    if (!(value.slice(0, 1) === "0")) return "10 Digits (Beginning with a 0)";
    if (!value.match(/^[0-9]*$/)) return "10 Digits (Beginning with a 0)";
}
