import React from "react";
import { Button, CircularProgress, List, ListItem } from "@material-ui/core";
import { IParcelState, ITransportState, ParcelType, useAppState } from "../../../states/app-state";
import { observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { error } from "../../../app/log"; // added by Bearer Web Developer
import styles from './ParcelEditor.module.scss'; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
import { analytics } from "../../../api/bearer-api"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer

const ParcelsEditor: React.FC<{ parcel: IParcelState, transport: ITransportState }> = observer(({ parcel, transport }) => {


    const appState = useAppState();

    const { parcelTypes } = appState;

    React.useEffect(() => {
        appState.fetchParcelTypes();
    }, [appState]);

    const [loading, setLoading] = React.useState(false);
    const handleConfirm = async () => {
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Parcel Type Chosen (Final)
        // App Event Parcel Type Confirmed by User
        if (appState?.selectedOrder?.parcelType?.type) {
            logEvent(analytics,"parcel_type_picked",{
                parcel_type: appState?.selectedOrder?.parcelType?.type?.parcel_type
            });
        }
        // added by Bearer Web Developer end
        try {
            setLoading(true);
            const r = await appState.selectedOrder?.fetchPricing();
            setLoading(false);
            r && transport.setEditing(true);
        } catch (err) {
            error(err); // modify by Bearer Web Developer
        }
    }
    // modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div>
                    <h2>Parcel Type</h2>
                </div>
                <Button variant="text" disableRipple={true} className={styles.clear} onClick={() => parcel.clear()}>Clear</Button>
            </div>
            <List component="nav" className={styles.list} aria-label="mailbox folders">
                {parcelTypes.map((parcelType, i) => <ParcelOption key={i} parcelType={parcelType} parcel={parcel}
                />)}
                {parcelTypes.length === 0 &&
                    <div className={styles.circular_progress}>
                        <CircularProgress />
                    </div>
                }
            </List>
            <div>
                <Button
                    className={styles.confirm}
                    onClick={handleConfirm}
                    disabled={loading}
                    disableRipple={true}
                >
                    Confirm
                </Button>
                {loading && <DisabledClickLoading />}
            </div>

        </div>
    )
    // modified by Bearer Web Developer end
});
export default ParcelsEditor;

const ParcelOption: React.FC<{ parcelType: ParcelType, parcel: IParcelState }> = observer(({ parcelType, parcel }) => {

    const selected = parcel.type?.parcel_type === parcelType.parcel_type;

    const handleParcelTypeSelected = () => {
        parcel.selectType(parcelType);
    }
    // modified by Bearer Web Developer start
    return (
        <ListItem button selected={selected} onClick={handleParcelTypeSelected} className={styles.list_item}>
            <div className={styles.type}>
                <div className={styles.image}>
                    <img src={parcelType.parcel_img_url} alt="" />
                </div>
                <div className={''} >{parcelType.parcel_type}</div>
            </div>
            <div className={styles.description}>
                <div>{parcelType.parcel_min_weight} ~ {parcelType.parcel_max_weight} kg max</div>
                <div>{parcelType.parcel_description}</div>
            </div>
        </ListItem>
    )
    // modified by Bearer Web Developer end
});

