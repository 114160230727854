// this file added by Bearer Web Developer
import { Button } from "@material-ui/core";
import styles from './editOriginLocation.module.scss';
import React from "react";

interface Props {
    setDefaultOriginFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EditOriginLocation: React.FC<Props> = ({setDefaultOriginFormOpen}) => {
    const handleDefaultOriginForm = () => {
        // open defaultOriginForm
        setDefaultOriginFormOpen(true)
    };

    // modified by Bearer Web Developer start
    return (
        <>
            
            <div className={styles.favourite_places} onClick={handleDefaultOriginForm}>
                <div className={styles.favourite_place_info}>
                    <div className={styles.location_favourite_place}>
                        <img src="/edit_location.svg" alt="edit location" />
                    </div>
                    <div className={styles.list_item_text}>
                        <h6>Set your default origin location</h6>
                        <p>Click here to add the address</p>
                    </div>
                </div>
                <Button>
                    <img src="/edit_location_pen.png" alt="edit" />
                </Button>
            </div>
        </>
    )
    // modified by Bearer Web Developer end
}
export default EditOriginLocation;