import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, Button } from '@material-ui/core';
import { useAppState } from '../states/app-state';
import styles from './Notifier.module.scss';

const Notifier: React.FunctionComponent<IProps> = observer((props) => {
  const appStore = useAppState();

  const { snackMessage } = appStore;

  const hide = () => {
    // handleClick added by Bearer Web Developer start
    if (snackMessage?.handleClick) {
      snackMessage?.handleClick();
    }
    // handleClick added by Bearer Web Developer end
    appStore.setSnackMessage(undefined);
  };

  if (!snackMessage) {
    return null;
  }

  return (
    // modified by Bearer Web Developer start
    <Dialog open={true} PaperProps={{ className: styles.root,style: { borderRadius: "10px" }  }} >
      <p>{snackMessage.message}</p>
      <Button onClick={hide} className={styles.button}><span>{snackMessage?.buttonMessage || "OK"}</span></Button> {/* modified by Bearer Web Developer*/}
    </Dialog>
    // modified by Bearer Web Developer end
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: 'bottom',
    //     horizontal: 'left',
    //   }}
    //   open={true}
    //   autoHideDuration={6000}
    //   onClose={hide}
    //   ContentProps={{
    //     'aria-describedby': 'message-id',
    //   }}
    //   message={snackMessage.message}
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="Close"
    //       color="inherit"
    //       className={classes.close}
    //       onClick={hide}
    //     >
    //       <CloseIcon />
    //     </IconButton>,
    //   ]}
    // />
  );
});

interface IProps {
}

export default (Notifier);
