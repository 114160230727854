import React from "react";
import { Button } from "@material-ui/core";
import TransportHistoriesPopup from "../order/TransportHistoriesPopup";
import { useAppState } from "../../states/app-state";
import FavouritePlacesList from "../favouritePlaces/FavouritePlacesList";
import LoginPopup from "../login/LoginPopup";
import PaymentMethod from "../price/PaymentMethod";
import { log } from "../../app/log"; // added by Bearer Web Developer
import styles from './MenuDrawer.module.scss'; // added by Bearer Web Developer
import BusinessName from "./businessName"; // added by Bearer Web Developer
// this component modified by Bearer Web Developer
const MenuDrawer: React.FC = () => {

    const appState = useAppState();
    const login = appState.login.loggedin;
    React.useEffect(() => {
        appState.getWebAppRemoteConfigParameters();
    }, [appState]);

    const [transportHistoryOpen, setTransportHistoryOpen] = React.useState(false);
    const handleTransportHistory = () => {
        setTransportHistoryOpen(true);
    }
    const handleTransportHistoryClose = () => {
        setTransportHistoryOpen(false);
    }
    const [paymentMethodOpen, setPaymentMethodOpen] = React.useState(false);
    // const handlePaymentMethod = () => {
    //     setPaymentMethodOpen(true);
    // }
    const handlePaymentMethodClose = () => {
        setPaymentMethodOpen(false);
    }

    const [logInPopupOpen, setLogInPopupOpen] = React.useState(false);
    const handleLoginInPopupClose = () => {
        setLogInPopupOpen(false);
    }
    const [favouritePlacesListOpen, setFavouritePlacesListOpen] = React.useState(false);
    const handleFavouritePlacesList = () => {
        if (!appState.login.loggedin) {
            setLogInPopupOpen(true)
        } else {
            setFavouritePlacesListOpen(true);
        }
    }
    const handleFavouritePlacesListClose = () => {
        setFavouritePlacesListOpen(false);
    }
    //  modified by Bearer Web Developer start
    return (
        <div className={styles.root}>
            <LoginPopup open={logInPopupOpen} handleClose={handleLoginInPopupClose} />
            {/* the fallowing log modified by Bearer Web Developer */}
            <FavouritePlacesList onSelect={(v) => { log(v) }} open={favouritePlacesListOpen} handleClose={handleFavouritePlacesListClose} />
            {/* added by Bearer Web Developer start  */}
            {
                login &&
                <>
                    <Button
                        onClick={handleTransportHistory}
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                <path d="M12.4583 20.125V16.2917C12.1708 15.2694 11.6636 14.5148 10.9369 14.0276C10.2102 13.5405 9.41554 13.2969 8.55304 13.2969C8.37735 13.2969 8.20165 13.3089 8.02596 13.3328C7.85027 13.3568 7.67457 13.3847 7.49888 13.4167L9.00825 14.95L7.66659 16.2917L3.83325 12.4583L7.66659 8.625L9.00825 9.96667L7.49888 11.5C7.64263 11.4681 7.79436 11.4441 7.95409 11.4281C8.11381 11.4122 8.28152 11.4042 8.45721 11.4042C9.15999 11.4042 9.86277 11.512 10.5655 11.7276C11.2683 11.9432 11.8992 12.2986 12.4583 12.7937V6.54062L10.9249 8.07396L9.58325 6.70833L13.4166 2.875L17.2499 6.70833L15.9083 8.05L14.3749 6.54062V20.125H12.4583Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Order History</span>
                    </Button>
                    <Button
                        onClick={handleFavouritePlacesList}
                        disableRipple={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M2.625 20.125V6.125C2.625 5.64375 2.79635 5.23177 3.13906 4.88906C3.48177 4.54635 3.89375 4.375 4.375 4.375H13.125C13.6062 4.375 14.0182 4.54635 14.3609 4.88906C14.7036 5.23177 14.875 5.64375 14.875 6.125V20.125L8.75 17.5L2.625 20.125ZM4.375 17.4562L8.75 15.575L13.125 17.4562V6.125H4.375V17.4562ZM16.625 17.5V2.625H5.25V0.875H16.625C17.1062 0.875 17.5182 1.04635 17.8609 1.38906C18.2036 1.73177 18.375 2.14375 18.375 2.625V17.5H16.625Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Saved Addresses</span>
                    </Button>
                </>
            }
            {
                !login &&
                <>
                    <Button
                        disabled={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                <path d="M12.4583 20.125V16.2917C12.1708 15.2694 11.6636 14.5148 10.9369 14.0276C10.2102 13.5405 9.41554 13.2969 8.55304 13.2969C8.37735 13.2969 8.20165 13.3089 8.02596 13.3328C7.85027 13.3568 7.67457 13.3847 7.49888 13.4167L9.00825 14.95L7.66659 16.2917L3.83325 12.4583L7.66659 8.625L9.00825 9.96667L7.49888 11.5C7.64263 11.4681 7.79436 11.4441 7.95409 11.4281C8.11381 11.4122 8.28152 11.4042 8.45721 11.4042C9.15999 11.4042 9.86277 11.512 10.5655 11.7276C11.2683 11.9432 11.8992 12.2986 12.4583 12.7937V6.54062L10.9249 8.07396L9.58325 6.70833L13.4166 2.875L17.2499 6.70833L15.9083 8.05L14.3749 6.54062V20.125H12.4583Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Order History</span>
                    </Button>
                    <Button
                        disabled={true}
                        className={styles.button}>
                        <span className={styles.icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M2.625 20.125V6.125C2.625 5.64375 2.79635 5.23177 3.13906 4.88906C3.48177 4.54635 3.89375 4.375 4.375 4.375H13.125C13.6062 4.375 14.0182 4.54635 14.3609 4.88906C14.7036 5.23177 14.875 5.64375 14.875 6.125V20.125L8.75 17.5L2.625 20.125ZM4.375 17.4562L8.75 15.575L13.125 17.4562V6.125H4.375V17.4562ZM16.625 17.5V2.625H5.25V0.875H16.625C17.1062 0.875 17.5182 1.04635 17.8609 1.38906C18.2036 1.73177 18.375 2.14375 18.375 2.625V17.5H16.625Z" fill="#14438E" />
                            </svg>
                        </span>
                        <span>Saved Addresses</span>
                    </Button>
                </>
            }
            <BusinessName /> {/* added by Bearer Web Developer */}
            {/* added by Bearer Web Developer end  */}
            {appState.login.loggedin &&
                <>
                    <PaymentMethod open={paymentMethodOpen} handleClose={handlePaymentMethodClose} />
                    {/* <SettingPopup open={editSettingOpen} handleClose={handleEditSettingClose} /> */} {/* commented by Bearer Web Developer  */}
                    <TransportHistoriesPopup open={transportHistoryOpen} handleClose={handleTransportHistoryClose} />
                </>
            }
        </div>
    );
    //  modified by Bearer Web Developer end
}
export default MenuDrawer;



